class Functions {
  static calculateDistance(lat, long, __lat, __long) {
    var distance = Math.sqrt(
      Math.pow(lat - __lat, 2) + Math.pow(long - __long, 2)
    );
    return distance;
  }

  static getLocationFromString(data) {
    let datas = data.split(",");

    return {
      lat: parseFloat(datas[0]),
      long: parseFloat(datas[1]),
    };
  }

  static getNearestLocationFromLocation(locations, location) {
    var nearest = locations[0];
    var distance = this.calculateDistance(
      location.lat,
      location.long,
      nearest.lat,
      nearest.long
    );

    locations.map((l) => {
      if (
        this.calculateDistance(l.lat, l.long, location.lat, location.long) <=
        distance
      ) {
        distance = this.calculateDistance(
          l.lat,
          l.long,
          location.lat,
          location.long
        );

        nearest = l;
      }

      return true;
    });

    return nearest;
  }

  static getServerFromLocation(servers, location) {
    var current = servers[0];

    servers.map((server) => {
      if (server.lat === location.lat && server.long === location.long) {
        current = server;
      }
      return true;
    });

    return current;
  }

  static draw(connection = 0) {
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    for (var i = 0; i <= 15; i++) {
      var x = canvas.width / 2;
      var y = canvas.height / 2;
      var radius = 140;
      var startAngle = (0.75 + i * 0.1) * Math.PI;
      var endAngle = (0.76 + i * 0.1) * Math.PI;
      var counterClockwise = false;

      context.beginPath();
      context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
      context.lineWidth = 15;

      context.strokeStyle = "rgba(14, 24, 40, 0.2)";
      context.stroke();
    }

    var speed = ((connection > 100 ? 100 : connection) * 1.5) / 100;

    x = canvas.width / 2;
    y = canvas.height / 2;
    radius = 140;
    startAngle = 0.75 * Math.PI;
    endAngle = (speed + 0.76) * Math.PI;
    counterClockwise = false;

    context.beginPath();
    context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
    context.lineWidth = 15;

    context.strokeStyle = "rgba(142, 87, 255, 1)";
    context.stroke();
  }

  static async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static createSettings(server) {
    return {
      latency: {
        measures: 1,
        attempts: 1,
        endpoint: server.api,
      },
      upload: {
        endpoint: server.api,
      },
      download: {
        endpoint: server.api,
      },
    };
  }

  static checkUploadSpeed(
    iterations,
    update,
    api = "https://internethizi.com/network/upload.php"
  ) {
    var average = 0,
      index = 0,
      timer = window.setInterval(check, 5000);
    check();

    function check() {
      var xhr = new XMLHttpRequest(),
        url = api + "?cache=" + Math.floor(Math.random() * 10000),
        data = getRandomString(1),
        startTime,
        speed = 0;
      xhr.onreadystatechange = function (event) {
        if (xhr.readyState === 4) {
          speed = Math.round(1024 / ((new Date() - startTime) / 1000));
          average === 0
            ? (average = speed)
            : (average = Math.round((average + speed) / 2));
          update(speed, average);
          index++;
          if (index === iterations) {
            window.clearInterval(timer);
          }
        }
      };
      xhr.open("POST", url, true);
      startTime = new Date();
      xhr.send(data);
    }

    function getRandomString(sizeInMb) {
      var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?",
        iterations = sizeInMb * 1024 * 1024,
        result = "";
      for (var index = 0; index < iterations; index++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    }
  }

  static lightOrDark() {
    if (localStorage.getItem("darkMode") === "enabled") {
      return true;
    } else if (localStorage.getItem("darkMode") === "disabled") {
      return false;
    }

    return false;
  }
}

export default Functions;
