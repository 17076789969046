const Resource = {
  Okay: "Bueno",
  Testing: "Pruebas",
  Repeat: "Repetir",
  CookieDescription:
    "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
  PrivacyPolicy: "Política de Privacidad",
  Contact: "Contacto",
  Network: "Red",
  Ping: "Silbido",
  Download: "Descarga",
  Upload: "Carga",
  Server: "Servidor",
  Client: "Cliente",
  WebsiteTitle:
    "Prueba de velocidad de Internet - Verificación de velocidad de Internet - Prueba de velocidad - Prueba de velocidad DSL",
  faqText: "FAQ",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "Política de Privacidad",
    },
    {
      type: "p",
      content:
        'Al visitar speedot.net y solicitar el uso de los servicios que ofrecemos a través de este sitio web, se considera que usted acepta las condiciones establecidas en esta "Política de Privacidad". La privacidad de los visitantes es muy importante para speedot.net. Nuestra política general de privacidad y las normas aplicables en nuestro sitio web se encuentran en esta página. Si se considera oportuno, speedot.net puede modificar la Política de Privacidad, por lo que le recomendamos que lea esta página cada vez que nos visite.',
    },
    {
      type: "h4",
      content: "Utilización y Protección de la Información",
    },
    {
      type: "p",
      content:
        "La información que obtenemos relacionada con quienes visitan nuestro sitio web y/o prueban su velocidad de Internet se protege confidencialmente con el máximo rigor. El personal de speedot.net puede acceder a esta información para cumplir con un servicio solicitado o en la medida en que lo requiera el servicio, y nunca se compartirá con terceros. speedot.net no acepta ninguna responsabilidad por las pérdidas que puedan surgir en caso de que el sistema de protección se desactive por piratería informática, etc.",
    },
    {
      type: "h4",
      content: "Fuentes de Información",
    },
    {
      type: "p",
      content:
        'Recibimos y registramos la información que usted proporciona al utilizar speedot.net o a través de otros medios como el correo electrónico. Esta información se obtiene con relación a sus visitas anteriores, los servicios que ha recibido, etc. mediante un sistema de identificación llamado "cookies" que permite a nuestros ordenadores identificarle de forma automática. En el menú de "ayuda" de su ordenador puede informarse de lo que debe hacer para limitar o desactivar completamente esta función. Nuestro sitio web utiliza Google Analytics. Google utiliza cookies como proveedor de terceros para anunciarse en nuestro sitio web. Google muestra anuncios relacionados con nuestros visitantes, el sitio web y las visitas a otros sitios web mediante el uso de cookies DART. Nuestros visitantes pueden visitar la política de privacidad de la red de anuncios y contenidos de Google para obtener más información si desean inhabilitar el uso de las cookies DART.',
    },
  ],
  Questions: [
    {
      title: "¿Qué es una red?",
      description:
        "Una red es un grupo que consiste en dos o más ordenadores u otros dispositivos electrónicos interconectados con el fin de transferir datos y compartir recursos.",
    },
    {
      title: "¿Qué es el ping?",
      description:
        "El ping es una señal que se envía al servidor para comprobar cuánto tarda en responder.",
    },
  ],
};

export default Resource;
