const Resource = {
  Okay: "Хорошо",
  Testing: "Тестирование",
  Repeat: "Повторение",
  CookieDescription:
    "Этот веб-сайт использует файлы cookie для улучшения взаимодействия с пользователем.",
  PrivacyPolicy: "политика конфиденциальности",
  Contact: "контакт",
  Network: "Сеть",
  Ping: "пинг",
  Download: "Скачать",
  Upload: "Загрузить",
  Server: "Сервер",
  Client: "Клиент",
  WebsiteTitle:
    "Тест скорости Интернета - Проверка скорости Интернета - Тест скорости - Тест скорости DSL",
  faqText: "FAQ",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "Политика конфиденциальности",
    },
    {
      type: "p",
      content:
        'Посещая сайт speedot.net и делая запрос на использование услуг, которые мы предлагаем через этот сайт, предполагается, что вы принимаете условия, изложенные в данной "Политике конфиденциальности". Конфиденциальность посетителей очень важна для speedot.net. Общая политика конфиденциальности и стандарты, действующие на нашем сайте, представлены на этой странице. В случае необходимости speedot.net может внести изменения в политику конфиденциальности, поэтому мы рекомендуем вам читать эту страницу при каждом посещении.',
    },
    {
      type: "h4",
      content: "Использование и защита информации",
    },
    {
      type: "p",
      content:
        "Информация, которую мы получаем о тех, кто посещает наш сайт и/или тестирует скорость своего интернета, конфиденциальна и охраняется с особой тщательностью. Персонал speedot.net может получить эту информацию для выполнения запрошенной услуги и в той степени, в которой это требуется услугой, и она никогда не передается третьим лицам. speedot.net не несет никакой ответственности за убытки, которые могут возникнуть в случае, если система защиты будет отключена в результате взлома и т.д.",
    },
    {
      type: "h4",
      content: "Источники информации",
    },
    {
      type: "p",
      content:
        'Мы получаем и записываем информацию, которую вы предоставляете, используя сайт speedot.net или другими способами, например, по электронной почте. Эта информация, связанная с вашими предыдущими посещениями, услугами, которые вы получили и т.д., собирается с помощью системы идентификации под названием "cookies", которая позволяет нашим компьютерам автоматически идентифицировать вас. В меню "помощь" вашего компьютера вы можете узнать, что нужно сделать, чтобы ограничить или полностью отключить эту функцию. Наш сайт использует Google Analytics. Google использует файлы cookie в качестве стороннего рекламодателя на нашем сайте. Google показывает рекламу, связанную с нашими посетителями, веб-сайтом и посещениями других веб-сайтов, используя DART-куки. Наши посетители могут ознакомиться с политикой конфиденциальности рекламной и контентной сети Google, чтобы получить дополнительную информацию, если они хотят отключить использование DART-куки.',
    },
  ],
  Questions: [
    {
      title: "Что такое сеть?",
      description:
        "Сеть - это группа, состоящая из двух или более компьютеров или других электронных устройств, соединенных между собой для передачи данных и совместного использования ресурсов.",
    },
    {
      title: "Что такое пинг?",
      description:
        "Пинг - это сигнал, посылаемый на сервер для проверки того, сколько времени требуется для получения ответа.",
    },
  ],
};

export default Resource;
