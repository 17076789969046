const Resource = {
  Okay: "D'accord",
  Testing: "Essai",
  Repeat: "Répéter",
  CookieDescription:
    "Ce site Web utilise des cookies pour améliorer l'expérience utilisateur.",
  PrivacyPolicy: "Politique de Confidentialité",
  Contact: "Contact",
  Network: "Réseau",
  Ping: "Ping",
  Download: "Descendant",
  Upload: "Ascendant",
  Server: "Serveur",
  Client: "Client",
  WebsiteTitle:
    "Test de vitesse Internet - Vérification de la vitesse Internet - SpeedTest - Test de vitesse DSL",
  faqText: "FAQ",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "Politique de confidentialité",
    },
    {
      type: "p",
      content:
        "En visitant speedot.net et en demandant l’utilisation des services que nous offrons par l’intermédiaire de ce site Web, vous êtes réputé avoir accepté les conditions énoncées dans cette « politique de confidentialité ». La confidentialité des visiteurs est très importante pour speedot.net. Notre politique générale de confidentialité et notre norme applicables sur notre site Web sont présentes sur cette page. S’il est jugé approprié, speedot.net peut modifier la politique de confidentialité, c’est pourquoi nous vous recommandons de lire cette page à chacune de vos visites.",
    },
    {
      type: "h4",
      content: "Utilisation et protection de l’information",
    },
    {
      type: "p",
      content:
        "Les informations que nous obtenons concernant ceux qui visitent notre site web et / ou testent leur vitesse Internet est confidentiellement protégé avec le plus grand soin. Le personnel de speedot.net peut joindre ces renseignements pour remplir un service demandé ou dans la mesure où ils sont requis par le service, et ils ne sont jamais partagés avec des tiers. speedot.net n’accepte aucune responsabilité pour les pertes qui peuvent survenir au cas où le système de protection serait désactivé par piratage, etc.",
    },
    {
      type: "h4",
      content: "Sources d’information",
    },
    {
      type: "p",
      content:
        "Nous recevons et en enregistrons les informations que vous fournissez en utilisant speedot.net ou par d’autres moyens tels que le courrier électronique. Ces informations sont obtenues en lien avec vos visites précédentes, les services que vous avez reçus, etc. par un système d’identification appelé « cookies » qui permet à nos ordinateurs de vous identifier automatiquement. Dans le menu « aide » de votre ordinateur, vous pouvez savoir ce que vous devez faire pour limiter ou désactiver complètement cette fonctionnalité. Notre site web utilise Google Analytics. Google utilise les cookies en tant que vendeur tiers pour faire de la publicité sur notre site Web. Google affiche des publicités liées à nos visiteurs, site Web et visites à d’autres sites Web en utilisant des cookies DART. Nos visiteurs peuvent visiter la politique de confidentialité des annonces et des réseaux de contenu Google pour obtenir de plus amples informations s’ils veulent désactiver l’utilisation des cookies DART.",
    },
  ],
  Questions: [
    {
      title: "Qu’est-ce qu’un réseau ?",
      description:
        "Un réseau est un groupe qui se compose de deux ordinateurs ou plus ou d’autres appareils électroniques interconnectés pour le transfert de données et le partage de sources.Un réseau est un groupe qui se compose de deux ordinateurs ou plus ou d’autres appareils électroniques interconnectés pour le transfert de données et le partage de sources.",
    },
    {
      title: "Qu’est-ce que Ping?",
      description:
        "Ping est un signal envoyé au serveur pour tester le temps qu’il faut pour une répondre.",
    },
  ],
};

export default Resource;
