import React from "react";
import ReactLoading from "react-loading";

const Detail = ({ title, subtitle, loading, icon, color = "normal", alt }) => (
  <div className="detail">
    <div className={"detail__icon detail__icon--" + color}>
      <img src={"i/icon-" + icon + ".svg"} alt={alt} />
    </div>
    <div className="detail__titles">
      <div className="detail__title">{title}</div>
      <div className="detail__subtitle">
        {loading ? (
          <ReactLoading
            type={"cylon"}
            color={"rgba(0,0,0,0.37)"}
            height={29}
            width={29}
          />
        ) : (
          subtitle
        )}
      </div>
    </div>
  </div>
);

export default Detail;
