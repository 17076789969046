import { Languages, Settings } from "../Helpers";

class Resources {
  static get(key, language = "english") {
    const Language = Settings.multilingual
      ? Languages[language]
      : Languages["turkish"];

    return Language[key] ? Language[key] : Languages["english"][key];
  }
}

export default Resources;
