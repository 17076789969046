const Servers = [
  {
    name: "İzmir",
    ip: "45.151.250.142",
    lat: 38.4167,
    long: 27.15,
    api: "https://www.internethizi.com/network/network.php",
    uploadApi: "https://www.internethizi.com/network/upload.php",
  },
  // {
  //   name: "Munich",
  //   ip: "188.68.47.238",
  //   lat: 48.1374,
  //   long: 11.5755,
  //   api: "https://peop.de/network/network.php",
  //   uploadApi: "https://peop.de/network/upload.php",
  // },
  // {
  //   name: "Denizli",
  //   ip: "89.252.159.34",
  //   lat: 37.7742,
  //   long: 29.0875,
  //   api: "https://speedot.co/network/network.php",
  //   uploadApi: "https://speedot.co/network/upload.php"
  // },
  // {
  //   name: "Amsterdam",
  //   ip: "160.153.133.160",
  //   lat: 52.374,
  //   long: 4.8897,
  //   api: "https://gumenita.com/network/network.php",
  //   uploadApi: "https://gumenita.com/network/upload.php",
  // },
];

export default Servers;
