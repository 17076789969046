import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Settings } from "../Helpers";
import LanguagesMenu from "./LanguagesMenu";
import Logo from "./Logo";
import Partners from "./Partners";
import Resources from "./Resources";

function useOuterClick(callback) {
  const innerRef = useRef();
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.getElementById("root").addEventListener("click", handleClick);
    return () =>
      document.getElementById("root").removeEventListener("click", handleClick);

    // read most recent callback and innerRef dom node from refs
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }
  }, []); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
}

const FAQStyle = {
  position: "fixed",
  right: 24,
  bottom: 24,
  backgroundColor: "white",
  padding: "8px 16px",
  borderRadius: 8,
  zIndex: 2,
  width: 400,
  boxSizing: "border-box",
  "-webkit-box-shadow": "0px 2px 4px 0px rgba(16,17,21,0.25)",
  "-moz-box-shadow": "0px 2px 4px 0px rgba(16,17,21,0.25)",
  "box-shadow": "0px 2px 4px 0px rgba(16,17,21,0.25)",
};

const FAQTitleStyle = { fontSize: 14, fontWeight: "600" };
const FAQDescriptionStyle = { fontSize: 14 };

const Menu = ({
  language,
  mobile = false,
  onClose,
  onOpen,
  open,
  change,
  partners,
}) => {
  const [faq, setFaq] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [selectedKey, setSelectedKey] = useState("EN");
  const [langMenu, setLangMenu] = useState(false);
  const innerRef = useOuterClick((e) => {
    if (faq) {
      setFaq(false);
    }
    if (showDrop) {
      setShowDrop(false);
    }
    if (langMenu) {
      setLangMenu(false);
    }
  });

  return (
    <>
      {faq ? (
        <div ref={innerRef} className="__faq" style={FAQStyle}>
          <div className="__faq--relative">
            <span onClick={() => setFaq(false)} className="__close">
              <img src="/i/icon-x.svg" alt="Sık sorulan sorular kapama" />
            </span>
            {Resources.get("Questions", language).map((question) => (
              <>
                <p style={FAQTitleStyle}>{question.title}</p>
                <p style={FAQDescriptionStyle}>{question.description}</p>
              </>
            ))}
          </div>
        </div>
      ) : undefined}
      <Helmet>
        <meta
          name="description"
          content={Resources.get("WebsiteDescription", language)}
        />
        <title>{Resources.get("WebsiteTitle", language)}</title>
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:site"
          content={Resources.get("Website", language)}
        />
        <meta
          name="twitter:title"
          content={Resources.get("WebsiteTitle", language)}
        />
        <meta
          name="twitter:description"
          content={Resources.get("WebsiteDescription", language)}
        />
        <meta name="twitter:image" content={Resources.get("Logo", language)} />
        <meta
          property="og:title"
          content={Resources.get("WebsiteTitle", language)}
        />
        <meta
          property="og:description"
          content={Resources.get("WebsiteDescription", language)}
        />
        <meta property="og:url" content={Resources.get("Website", language)} />
        <meta property="og:image" content={Resources.get("Logo", language)} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className={open ? "modal modal--open" : "modal"}>
        <div className="menu">
          <Logo />
          <nav>
            <button className="ghost-button" onClick={onClose}>
              <img
                className="small-icon"
                src="/i/icon-close.svg"
                alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
              />
            </button>
          </nav>
        </div>
        {language === "turkish" || language === "english" ? (
          <Link to={"/blogs"} rel="noopener noreferrer" className="modal-item">
            Blogs
          </Link>
        ) : null}
        <Link
          to={
            Settings.multilingual ? "/privacy-policy" : "/gizlilik-politikasi"
          }
          className="modal-item"
        >
          {Resources.get("PrivacyPolicy", language)}
        </Link>
        <Link
          to={Resources.get("Email")}
          rel="noopener noreferrer"
          target="_blank"
          className="modal-item"
        >
          {Resources.get("Contact", language)}
        </Link>
        {/* <a
          href="#"
          rel="noopener noreferrer"
          target="_blank"
          className="modal-item"
        >
          API
        </a> */}
        <div className="modal-item">
          <a
            href={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              Resources.get("Website", language)
            }
            rel="noopener noreferrer"
            target="_blank"
            className="social"
          >
            <img
              src="/i/icon-facebook.svg"
              alt="Facebook'da Paylaş - İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
            />
          </a>
        </div>
        <div className="modal-item">
          <a
            href={
              "http://twitter.com/share?url=" +
              Resources.get("Website", language)
            }
            target="_blank"
            rel="noopener noreferrer"
            className="social"
          >
            <img
              src="/i/icon-twitter.svg"
              alt="Twitter'da Paylaş - İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
            />
          </a>
        </div>
      </div>
      <div className="menu">
        <Logo />
        <nav>
          {Settings.multilingual && change ? (
            <div ref={innerRef} className="custom-select">
              <div className="languagesWrapper">
                <a
                  onClick={() => {
                    setLangMenu(!langMenu);
                    setShowDrop(false);
                  }}
                  href="#"
                  className="nav__item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {selectedKey}
                  <img
                    style={{ marginLeft: "5px" }}
                    width={15}
                    src="/i/icon-down.svg"
                    alt="partners icon"
                  />
                </a>
                {langMenu ? (
                  <LanguagesMenu
                    change={change}
                    selectedKey={(key) => setSelectedKey(key)}
                    setLangMenu={() => setLangMenu(!langMenu)}
                  />
                ) : null}
              </div>
              {!mobile ? (
                <div className="partnersWrapper">
                  <a
                    onClick={() => {
                      setShowDrop(!showDrop);
                      setLangMenu(false);
                    }}
                    href="#"
                    className="nav__item"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Partners{" "}
                    <img
                      style={{ marginLeft: "5px" }}
                      width={15}
                      src="/i/icon-down.svg"
                      alt="partners icon"
                    />
                  </a>
                  {showDrop && !mobile ? (
                    <Partners
                      partners={partners}
                      showDrop={() => setShowDrop(!showDrop)}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : undefined}
          {mobile ? (
            <>
              <div ref={innerRef} className="partnersWrapper">
                <a
                  onClick={() => {
                    setShowDrop(!showDrop);
                    setLangMenu(false);
                  }}
                  href="#"
                  className="nav__item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  Partners
                  <img
                    width={15}
                    style={{ marginLeft: "5px" }}
                    src="/i/icon-down.svg"
                    alt="partners icon"
                  />
                </a>
                {showDrop ? (
                  <Partners
                    partners={partners}
                    showDrop={() => setShowDrop(!showDrop)}
                  />
                ) : null}
              </div>
              <div
                onClick={() => setFaq(!faq)}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 999,
                  backgroundColor: "#f6f6f6",
                  marginLeft: 8,
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src="/i/icon-info.svg"
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
              </div>
              <button
                className="ghost-button"
                style={{ marginLeft: 12 }}
                onClick={onOpen}
              >
                <img
                  className="small-icon"
                  src="/i/icon-menu.svg"
                  alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
                />
              </button>
            </>
          ) : (
            <>
              <Link
                to={
                  Settings.multilingual
                    ? "/privacy-policy"
                    : "/gizlilik-politikasi"
                }
                className="nav__item"
              >
                {Resources.get("PrivacyPolicy", language)}
              </Link>
              {/* <a
                href="#"
                rel="noopener noreferrer"
                target="_blank"
                className="nav__item"
              >
                API
              </a> */}
              {language === "turkish" || language === "english" ? (
                <Link
                  to={"/blogs"}
                  rel="noopener noreferrer"
                  className="nav__item"
                >
                  Blogs
                </Link>
              ) : null}
              <a
                href="mailto:ads@speedot.net"
                rel="noopener noreferrer"
                target="_blank"
                className="nav__item"
              >
                {Resources.get("Contact", language)}
              </a>

              <div
                onClick={() => setFaq(!faq)}
                style={{
                  marginLeft: 24,
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="social tooltip"
              >
                <img
                  src="/i/icon-info.svg"
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                <span className="tooltip__info">
                  {Resources.get("faqText", language)}{" "}
                </span>
              </div>

              <a
                href="https://www.youtube.com/channel/UClqJAJ-xYqR29Vn0Vvu13zA"
                rel="noopener noreferrer"
                target="_blank"
                className="social"
              >
                <img
                  width="24px"
                  height="24px"
                  src="/i/icon-youtube.png"
                  alt="Facebook'da Paylaş - İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
                />
              </a>
              <a
                href="https://re4mer.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="social"
              >
                <img
                  src="/i/icon-re4mer.png"
                  alt="Re4mer.com"
                  style={{ width: 20, height: 20 }}
                />
              </a>
            </>
          )}
        </nav>
      </div>
    </>
  );
};

export default Menu;
