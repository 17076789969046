import Airtable from "airtable";
import Store from "./Store";

export default class API {
  static blogs() {
    const Base = new Airtable({ apiKey: "keyGo3Ntky34XDYu8" }).base(
      "appfx4jJ7E9utYOl3"
    );

    Base("Blogs")
      .select({
        filterByFormula: "{Project} = 'Speed Test'",
      })
      .firstPage(function (error, records) {
        var blogs = [];

        records.map((record) => {
          var blog = {};
          blog.id = record.id;
          blog.title = record.get("Title");
          blog.trTitle = record.get("TurkishTitle");
          blog.content = record.get("Content");
          blog.trContent = record.get("TurkishContent");
          blog.language = record.get("Language");
          blog.src = record.get("Attachment")[0].url;
          blogs.push(blog);
          return true;
        });

        Store.dispatch({ type: "CORE", key: "blogs", value: blogs });
      });

    return true;
  }

  static post(id, language) {
    const Base = new Airtable({ apiKey: "keyGo3Ntky34XDYu8" }).base(
      "appfx4jJ7E9utYOl3"
    );

    Base("Blogs").find(id, function (error, record) {
      var blog = {};
      blog.id = record.id;
      blog.title =
        language === "turkish"
          ? record.get("TurkishTitle")
          : record.get("Title");
      blog.content =
        language === "turkish"
          ? record.get("TurkishContent")
          : record.get("Content");
      blog.src = record.get("Attachment")[0].url;

      Store.dispatch({ type: "CORE", key: "blog", value: blog });
    });

    return true;
  }

  static static(key, language) {
    const Base = new Airtable({ apiKey: "keyGo3Ntky34XDYu8" }).base(
      "app02iJcuHjpO7vyR"
    );

    Base("Static")
      .select({
        filterByFormula: "{Key} = '" + key + "'",
      })
      .firstPage(function (err, records) {
        records.forEach(function (record) {
          Store.dispatch({
            type: "CORE",
            key: "static",
            value: {
              title:
                language === "turkish"
                  ? record.get("TurkishTitle")
                  : record.get("Title"),
              content:
                language === "turkish"
                  ? record.get("TurkishContent")
                  : record.get("Content"),
            },
          });
        });
      });

    return true;
  }

  static partners() {
    const Base = new Airtable({ apiKey: "keyGo3Ntky34XDYu8" }).base(
      "appeOTSPC3Blgl2JZ"
    );

    Base("Partners")
      .select({
        filterByFormula: "NOT({Path} = '')",
      })
      .firstPage(function (err, records) {
        var partners = [];
        records.forEach(function (record, index) {
          var partner = {};
          partner.id = index;
          partner.path = record.get("Path");
          partner.text = record.get("Text");
          partners.push(partner);
          return true;
        });
        Store.dispatch({
          type: "CORE",
          key: "partners",
          value: partners,
        });
      });

    return true;
  }
}
