import { connect } from "react-redux";
import React, { Component } from "react";
import { ScreenClassRender } from "react-grid-system";
import { Menu } from "../Components";
import { PostContainer } from "../Containers";
import { Functions } from "../Helpers";
class PostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      darkMode: Functions.lightOrDark(),
    };
  }
  render() {
    return (
      <div className={this.state.darkMode ? "app app--dark" : "app"}>
        <ScreenClassRender
          render={(screen) => (
            <Menu
              language={this.props.redux.language}
              mobile={["xs", "sm"].includes(screen)}
              open={this.state.menuOpen}
              onOpen={() => this.setState({ menuOpen: true })}
              onClose={() => this.setState({ menuOpen: false })}
            />
          )}
        />
        <PostContainer blog={this.props.match.params.blog} />
      </div>
    );
  }
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map)(PostPage);
