const Resource = {
  Okay: "好的",
  Testing: "测验",
  Repeat: "重复",
  CookieDescription: "本网站使用cookie来增强用户体验。",
  PrivacyPolicy: "隐私政策",
  Contact: "联系",
  Network: "网络",
  Ping: "平",
  Download: "下载",
  Upload: "上载",
  Server: "服务器",
  Client: "客户",
  WebsiteTitle: "互联网速度测试-互联网速度检查-SpeedTest-DSL速度测试",
  faqText: "FAQ",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "隐私政策",
    },
    {
      type: "p",
      content:
        "若您访问 speedot.net，并要求使用我们通过本网站提供的服务，则视为已接受本“私隐政策”所规定的条件。访问者的隐私Speedot.net非常重要。本页面包含了我们的一般隐私政策和适用标准。如果合适的话，speedot.net可能会修改隐私政策，因此我们建议您，在每次访问时都要阅读本页面。",
    },
    {
      type: "h4",
      content: "信息的使用与保护",
    },
    {
      type: "p",
      content:
        "什么人访问过我们的网站，和/或用我们的网站测试过网速，我们一旦获取此类信息，就会对其严格保密。speedot.net的工作人员有权接触这些信息，用以完成服务，或在服务要求的范围内确保这些信息绝不会被共享给第三方。speedot.net不承担任何责任，如果保护系统因黑客攻击而失效，可能会造成损失。 ",
    },
    {
      type: "h4",
      content: "信息来源",
    },
    {
      type: "p",
      content:
        '我们接收并记录您通过使用speedot.net或其他方式（如电子邮件）提供的信息。这些信息通过一个称为 "cookie "的识别系统获得，该系统让我们的计算机能够自动识别您的身份，与您以前的访问记录、您接受过的服务等有关。您可在计算机的“帮助”菜单中，选择限制或禁用此功能。我们的网站使用谷歌分析。谷歌作为第三方卖家，使用cookie在我们的网站上投放广告。谷歌通过DART cookies显示广告，这些广告与我们的访问者、网站以及对其它网站的访问有有关。若想禁用DART Cookies, 请您参阅 谷歌广告和内容网络隐私策略。 ',
    },
  ],
  Questions: [
    {
      title: "什么是网络？",
      description:
        "网络是一个由两台或更多的计算机或其他电子设备相互连接的群组，用于数据传输和资源共享。",
    },
    {
      title: "什么是Ping",
      description: "Ping是发送到服务器的信号，用于测试响应所需的时长。",
    },
  ],
};

export default Resource;
