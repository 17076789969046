import React from "react";
import Text from "./Text";

const Component = ({ children, bottom }) => (
  <Text size={20} line={34} weight="600" block bottom={bottom}>
    {children}
  </Text>
);

export default Component;
