import React from "react";

function LanguagesMenu({ change, selectedKey, setLangMenu }) {
  const languages = [
    {
      key: "EN",
      value: "english",
    },
    {
      key: "CN",
      value: "chinese",
    },
    {
      key: "ES",
      value: "spanish",
    },
    {
      key: "DE",
      value: "german",
    },
    {
      key: "FR",
      value: "french",
    },
    {
      key: "JP",
      value: "japanese",
    },
    {
      key: "TR",
      value: "turkish",
    },
    {
      key: "RU",
      value: "russian",
    },
    {
      key: "AR",
      value: "arabic",
    },
  ];
  return (
    <>
      <div className="languagesMenu">
        {languages.length > 0
          ? languages.map((language) => (
              <a
                onClick={() => {
                  selectedKey(language.key);
                  change(language.value);
                  setLangMenu();
                }}
                href="#"
                className="languagesMenu__item nav__item"
              >
                {language.key}
              </a>
            ))
          : null}
      </div>
    </>
  );
}

export default LanguagesMenu;
