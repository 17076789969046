import React from "react";
import CookieConsent from "react-cookie-consent";
import { ScreenClassRender } from "react-grid-system";
import { API } from "../Modules";
import Axios from "axios";
import { Functions, Servers, Settings } from "../Helpers";
import {
  Button,
  Detail,
  Menu,
  Resources,
  Speed,
  ThemeSwitcher,
} from "../Components";
import { connect } from "react-redux";
import { Store } from "../Modules";

class SpeedPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const browser =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    const supported = ["tr", "en", "ru", "cn", "es", "de", "fr", "ar", "jp"];
    const languages = {
      tr: "turkish",
      en: "english",
      ru: "russian",
      cn: "chinese",
      es: "spanish",
      de: "german",
      fr: "french",
      ar: "arabic",
      jp: "japanese",
    };

    const language = supported.includes(browser)
      ? languages[browser]
      : "english";

    this.state = {
      loading: true,
      ping: null,
      network: null,
      upload: null,
      download: null,
      server: null,
      client: null,
      d: [],
      average: 0,
      kbps: false,
      dark: Functions.lightOrDark(),
      language: language,
      menuOpen: false,
      faq: false,
    };

    this.reload = this.reload.bind(this);
  }

  async __latency() {
    this.setState({
      ping: {
        subtitle: parseInt(window.LATENCY) + " ms",
      },
    });
  }

  async __download() {
    this.setState({
      download: {
        subtitle:
          parseFloat(Number.isNaN(window.SPEED) ? 0 : window.SPEED).toFixed(1) +
          " Mbps",
        value: parseInt(Number.isNaN(window.SPEED) ? 0 : window.SPEED),
        speed: parseFloat(Number.isNaN(window.SPEED) ? 0 : window.SPEED),
        kbps: false,
      },
    });
    Functions.draw(window.SPEED);
  }

  async __upload() {
    this.setState({
      upload: {
        subtitle:
          parseFloat(Number.isNaN(window.UP) ? 0 : window.UP).toFixed(1) +
          " Mbps",
      },
    });
  }

  async __end() {
    this.setState({
      loading: false,
    });
  }

  async __connection() {
    try {
      var network = navigator.connection.effectiveType.toUpperCase();
      this.setState({ network: { subtitle: network } });
    } catch (e) {
      this.setState({ network: { subtitle: "Diğer" } });
    }
  }

  async __client() {
    // const { data } = await Axios.get(
    //   "https://ipinfo.io/8.8.8.8/?token=56705f319e462b"
    // );

    const options = {
      method: "GET",
      url: "https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation",
      params: { apikey: "873dbe322aea47f89dcf729dcc8f60e8" },
      headers: {
        "X-RapidAPI-Host":
          "find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com",
        "X-RapidAPI-Key": "6885d747d1mshe1a8038c13689fbp1b8044jsn8c1fe84bebfb",
      },
    };
    let data;
    await Axios.request(options)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        console.error(error);
      });

    if (data) {
      this.setState({
        client: {
          subtitle: data.org,
          content: data.ip,
        },
        location: `${data.latitude},${data.longitude}`,
      });
    }
  }

  async __server(server) {
    this.setState({
      server: {
        subtitle: server.name,
        content: server.ip,
      },
      api: server.api,
    });
  }

  async reload() {
    window.FUNCTIONS = {};
    window.FUNCTIONS.__latency = this.__latency.bind(this);
    window.FUNCTIONS.__download = this.__download.bind(this);
    window.FUNCTIONS.__upload = this.__upload.bind(this);
    window.FUNCTIONS.__end = this.__end.bind(this);

    this.setState({
      loading: true,
      ping: null,
      network: null,
      upload: null,
      download: null,
      server: null,
      client: null,
      kbps: false,
      location: "41.1065,28.8685",
      api: "https://www.internethizi.com/network/network.php",
    });

    Functions.draw(0);

    await Functions.sleep(500);

    await this.__client();

    const server = Functions.getServerFromLocation(
      Servers,
      Functions.getNearestLocationFromLocation(
        Servers,
        Functions.getLocationFromString(this.state.location)
      )
    );

    window.NET.settings(Functions.createSettings(server));

    await Functions.sleep(500);

    this.__server(server);

    await Functions.sleep(500);

    this.__connection();

    await Functions.sleep(500);

    try {
      window.NET.latency
        .on("end", async function (averageLatency, allLatencies) {
          window.LATENCY = averageLatency;
          window.FUNCTIONS.__latency();

          await Functions.sleep(500);

          window.NET.download
            .on("progress", function (averageSpeed, instantSpeed) {
              window.SPEED = ((averageSpeed / 1024 / 1024) * 8).toFixed(2);
              window.FUNCTIONS.__download();
            })
            .on("end", async function (averageSpeed, allInstantSpeeds) {
              window.SPEED = ((averageSpeed / 1024 / 1024) * 8).toFixed(2);
              window.FUNCTIONS.__download();

              await Functions.sleep(500);

              Functions.checkUploadSpeed(
                1,
                function (speed, average) {
                  window.UP = average / 100;
                  window.FUNCTIONS.__upload();
                  window.FUNCTIONS.__end();
                },
                server.uploadApi
              );
            })
            .start();
        })
        .start();
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  setDarkMode = () => {
    this.state.dark === true
      ? localStorage.setItem("darkMode", "disabled")
      : localStorage.setItem("darkMode", "enabled");
    this.setState({ dark: Functions.lightOrDark() });
  };

  componentDidMount() {
    this.reload();
    API.partners();
  }

  render() {
    return (
      <div className={this.state.dark ? "app app--dark" : "app"}>
        <ScreenClassRender
          render={(screen) =>
            ["xs", "sm"].includes(screen) ? null : (
              <ThemeSwitcher
                dark={this.state.dark}
                onClick={() => this.setDarkMode()}
              />
            )
          }
        />
        <CookieConsent
          location="bottom"
          buttonText={Resources.get("Okay", this.props.redux.language)}
          cookieName="consent"
          style={{ background: "#0E1828" }}
          buttonStyle={{
            padding: "0 16px",
            lineHeight: "48px",
            color: "#0E1828",
            backgroundColor: "white",
            borderRadius: 6,
            fontWeight: "600",
            fontSize: 12,
          }}
          expires={150}
        >
          {Resources.get("CookieDescription", this.props.redux.language)}{" "}
          <a
            href={
              Settings.multilingual ? "/privacy-policy" : "/gizlilik-politikasi"
            }
            style={{ color: "white", fontWeight: "700" }}
          >
            {Resources.get("PrivacyPolicy", this.props.redux.language)}
          </a>
        </CookieConsent>
        <ScreenClassRender
          render={(screen) => (
            <Menu
              change={(value) =>
                Store.dispatch({
                  type: "CORE",
                  key: "language",
                  value,
                })
              }
              language={this.props.redux.language}
              mobile={["xs", "sm"].includes(screen)}
              open={this.state.menuOpen}
              onOpen={() => this.setState({ menuOpen: true })}
              onClose={() => this.setState({ menuOpen: false })}
              partners={this.props.redux.partners}
            />
          )}
        />
        <div className="container">
          <Speed
            loading={this.state.download === null}
            value={
              this.state.download !== null
                ? this.state.download.speed.toFixed(1)
                : null
            }
            kbps={this.state.kbps}
            dark={this.state.dark}
            onClick={() => this.setDarkMode()}
          />
          <div className="direction-column-reverse--mobile">
            <div>
              <div className="direction-row--mobile">
                <div className="row">
                  <div className="column">
                    <Detail
                      title={Resources.get(
                        "Network",
                        this.props.redux.language
                      )}
                      loading={this.state.network === null}
                      subtitle={
                        this.state.network !== null
                          ? this.state.network.subtitle
                          : null
                      }
                      icon="network"
                      alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
                    />
                  </div>
                  <div className="column">
                    <Detail
                      title={Resources.get("Ping", this.props.redux.language)}
                      loading={this.state.ping === null}
                      subtitle={
                        this.state.ping !== null
                          ? this.state.ping.subtitle
                          : null
                      }
                      icon="ping"
                      color="yellow"
                      alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <Detail
                      title={Resources.get(
                        "Download",
                        this.props.redux.language
                      )}
                      loading={this.state.download === null}
                      subtitle={
                        this.state.download !== null
                          ? this.state.download.subtitle
                          : null
                      }
                      icon="download"
                      color="green"
                      alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
                    />
                  </div>
                  <div className="column">
                    <Detail
                      title={Resources.get("Upload", this.props.redux.language)}
                      loading={this.state.upload === null}
                      subtitle={
                        this.state.upload !== null
                          ? this.state.upload.subtitle
                          : null
                      }
                      icon="upload"
                      color="red"
                      alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row--padding--mobile">
                <div className="row row--auto">
                  <div className="column column--auto">
                    <SmallDetail
                      title={Resources.get("Server", this.state.language)}
                      icon="server"
                      loading={this.state.server === null}
                      subtitle={
                        this.state.server !== null
                          ? this.state.server.subtitle
                          : null
                      }
                      content={
                        this.state.server !== null
                          ? this.state.server.content
                          : null
                      }
                    />
                  </div>
                  <div className="column column--auto">
                    <SmallDetail
                      title={Resources.get("Client", this.state.language)}
                      icon="browser"
                      loading={this.state.client === null}
                      subtitle={
                        this.state.client !== null
                          ? this.state.client.subtitle
                          : null
                      }
                      content={
                        this.state.client !== null
                          ? this.state.client.content
                          : null
                      }
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="center" margin="small">
              <Button
                loading={this.state.loading}
                onClick={this.reload}
                repeat={Resources.get("Repeat", this.props.redux.language)}
                testing={Resources.get("Testing", this.props.redux.language)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(SpeedPage);
