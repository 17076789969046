import {
  Turkish,
  English,
  Russian,
  Chinese,
  Spanish,
  German,
  French,
  Japanese,
  Arabic,
} from "../Resource";

const Languages = {
  turkish: Turkish,
  english: English,
  russian: Russian,
  chinese: Chinese,
  spanish: Spanish,
  german: German,
  french: French,
  japanese: Japanese,
  arabic: Arabic,
};

export default Languages;
