const Colors = {
  space: "#1B2C55",
  amaranth: "#99256D",
  rose: "#FC5C9C",
  mimi: "#FCCDE2",
  platinum: "#E5E5E5",
  independence: "#57586E",
  white: "#FFFFFF",
  black: "#000000",
  cultured: "#F4F4F4",
  manatee: "#959EAD",
  russian: "#0A1045",
};

export default Colors;
