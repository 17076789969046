import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { Project } from "../Components";
import { API } from "../Modules";

class Component extends React.Component {
  componentDidMount() {
    API.blogs();
  }

  render() {
    return (
      <div className="container" style={{ paddingTop: "50px" }}>
        <Container>
          <Row>
            {this.props.redux.blogs.map((blog, key) => (
              <Col xs={12} sm={12} md={6} lg={3} key={key}>
                <Project
                  id={blog.id}
                  title={
                    this.props.redux.language === blog.language
                      ? blog.trTitle
                      : blog.title
                  }
                  src={blog.src}
                  content={
                    this.props.redux.language === blog.language
                      ? blog.trContent.substring(0, 64) + "..."
                      : blog.content.substring(0, 64) + "..."
                  }
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
