import React from "react";

const ThemeSwitcher = ({ dark, onClick, menu, language = "english" }) =>
  dark ? (
    <button
      onClick={onClick}
      className={menu ? "theme theme--dark theme--menu" : "theme theme--dark"}
    >
      <img src="i/icon-moon.svg" alt="Tema Değiştirme Butonu" />
    </button>
  ) : (
    <button onClick={onClick} className={menu ? "theme theme--menu" : "theme"}>
      <img src="i/icon-sun.svg" alt="Tema Değiştirme Butonu" />
    </button>
  );

export default ThemeSwitcher;
