import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import { Resources } from "./Components";
import { BlogPage, PolicyPage, PostPage, SpeedPage } from "./Pages";
import { Settings } from "./Helpers";
import { Store } from "./Modules";

class App extends React.PureComponent {
  componentDidMount() {
    ReactGA.initialize(Resources.get("Analytics", "english"));
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Provider store={Store}>
        <Router>
          <Switch>
            <Route exact path="/" component={SpeedPage} />
            <Route exact path="/blogs" component={BlogPage} />
            <Route exact path="/blog/:blog" component={PostPage} />
            <Route
              exact
              path={
                Settings.multilingual
                  ? "/privacy-policy"
                  : "/gizlilik-politikasi"
              }
              component={PolicyPage}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
