import React, { Component } from "react";

class Partners extends Component {
  render() {
    return (
      <div className="partners">
        {this.props.partners.length > 0
          ? this.props.partners.map((partner) =>
              partner.path !== "https://speedot.net/" ? (
                <a
                  onClick={() => {
                    this.props.showDrop();
                  }}
                  href={partner.path}
                  className="partners__item nav__item"
                  target="_blank"
                >
                  {partner.text}
                </a>
              ) : null
            )
          : null}
      </div>
    );
  }
}
export default Partners;
