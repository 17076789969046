const Resource = {
  Okay: "Okay",
  Testing: "Testing",
  Repeat: "Repeat",
  CookieDescription:
    "This website uses cookies to enhance the user experience.",
  PrivacyPolicy: "Privacy Policy",
  Contact: "Contact",
  Network: "Network",
  Ping: "Ping",
  Download: "Download",
  Upload: "Upload",
  Server: "Server",
  Client: "Client",
  WebsiteDescription:
    "Test your download speed, upload speed for the internet service provider of you in a very short period of time with Speedot. Run your internet speed test with the best, Speedot. Internet Speed Test - Internet Speed Check - SpeedTest - DSL Speed Test",
  WebsiteTitle:
    "Internet Speed Test - Internet Speed Check - SpeedTest - DSL Speed Test",
  Website: "https://speedot.net",
  Logo: "https://speedot.net/i/logo.png",
  Analytics: "UA-168287737-4",
  Email: "mailto:ads@speedot.net",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "Privacy Policy",
    },
    {
      type: "p",
      content:
        'By visiting speedot.net and requesting to benefit from the services we offer through this site, you are deemed to have accepted the terms specified in this "Privacy Policy". For speedot.net, the privacy of its visitors is very important. Our general privacy policy and standards belonging to our site are available on this page. speedot.net may change its privacy policy if it sees any need, therefore we recommend that you read this page every time when you visit.',
    },
    {
      type: "h4",
      content: "Use and Protection of Data",
    },
    {
      type: "p",
      content:
        "The information we obtain about those who visit our site and / or test the internet speed are protected confidentially with the utmost care we take. This information can be accessed by speedot.net personnel in order to fulfill the service requested and to the extent required by the service, and it is not shared with the third parties. speedot.net does not accept any responsibility for any damages that may arise if the protection system is ineffective through hacking etc.",
    },
    {
      type: "h4",
      content: "Data Sources",
    },
    {
      type: "p",
      content:
        'The information you provide to us by using the internet site or other means such as e-mail is collected and recorded by us. During your visit to speedot.net, it is the information obtained through the identification system called "cookies", which allows our computers to automatically recognize you, such as your previous visits to our site and the services you have received. In the "help" menu of your computer, you can find what you need to do to restrict or disable this feature entirely. Google Analytics is used on our site. Google, as a third party vendor, uses cookies to serve ads on our site. By using DART cookies, Google shows advertisements for our visitors, visits to our site and other sites on the internet. If our visitors want to prevent the use of the DART cookies: they can get information by visiting the link.',
    },
  ],
  Questions: [
    {
      title: "Is it free?",
      description: "Any service that speedot.co provides is free.",
    },
    {
      title: "How it is calculating?",
      description: "We are using the nearest server based on your location.",
    },
  ],
  faqText: "FAQ",
};

export default Resource;
